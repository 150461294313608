import { formatInputDateToUTCDate } from "./convertDateTime";
import { generateAlphaNumericUnique } from "@/utils/helpers/generateAlphaNumericUnique";
import { IFacilitiesByCountry } from "@/models/Metadata";
import isNumber from "lodash/isNumber";
import isNaN from "lodash/isNaN";
import isEmpty from "lodash/isEmpty";

export const buildAndFormatDataScope = (data: any) => {
  const monthArrayFormatted = data.MonthValue
    ? data.MonthValue.map((item: any, idx: number) => {
        return {
          Month: idx + 1,
          Value: item ? item.replaceAll(",", "") : "0",
        };
      })
    : [];
  data.MonthValue = monthArrayFormatted;
  return data;
};
export const buildAndFormatDataStringToNumber = (data: any) => {
  const result = data ? data.replaceAll(",", "") : "0";
  return result;
};
export const formatNumber = (num: number, precision?: number): string => {
  if (!isNumber(num)) {
    return "";
  }
  const _num = isNumber(precision) ? num.toFixed(precision) : num;
  return _num
    .toString()
    .replace(/^[+-]?\d+/, (int) => int.replace(/(\d)(?=(\d{3})+$)/g, "$1,"));
};

export const removeNumberFormat = (string: any) => {
  return string && string.length > 0 ? string.replaceAll(",", "") : 0;
};

export const validateInputNumberType = (num: number) => {
  if (!num || isNaN(num)) return 0;
  const result = num.toString().replace(/[^0-9]/g, "");
  return result;
};

export const convertDataToMonthValue = (data: any) => {
  if (!data) return { MonthValue: [] };
  const arrValues = Object.values(data);
  const result = arrValues.map((value: any) => {
    return value;
  });
  return { MonthValue: result };
};

export const convertDataToMonthValueWithFormat = (data: any) => {
  if (isEmpty(data)) return { MonthValue: [] };
  const arrValues = Object.values(data);
  const result = arrValues.map((value: any) => {
    return formatNumber(value, 0);
  });
  return { MonthValue: result };
};

export const convertNewDataToMonthValueWithFormat = (data: any) => {
  if (isEmpty(data)) return { MonthValue: [] };
  const arrValues = Object.values(data);
  const result = arrValues.map((value: any) => {
    return formatNumber(value.Value, 0);
  });
  return { MonthValue: result };
};

export const distinctCountriesData = (data: any) => {
  if (!data) return [];
  const tempData = [] as any;
  data.forEach((item: any) => {
    if (item["CountryName"] && item["CountryId"]) {
      tempData.push([
        item["CountryName"],
        {
          Name: item["CountryName"],
          Value: item["CountryId"],
        },
      ]);
    }
  });
  const countriesData = [...new Map(tempData).values()];
  return countriesData;
};
export const distinctCountriesDataAll = (data: any) => {
  const tempData = data.map((item: any) => [
    item["CountryName"],
    {
      Name: item["CountryName"],
      Value: item["CountryId"],
    },
  ]);
  const countriesData = [...new Map(tempData).values()];
  countriesData.unshift({ Name: "All", Value: "" });
  return countriesData;
};

export const distinctFacilitiesData = (data: any) => {
  const tempData = data.map((item: any) => [
    item["CountryName"],
    {
      Name: item["CountryName"],
      Value: item["CountryId"],
    },
  ]);

  const facilitiesData = [...new Map(tempData).values()];
  facilitiesData.unshift({ Name: "All", Value: "" });
  return facilitiesData;
};
export const generateMonthsTotal = (
  monthTotals: any,
  monthsName: Array<{ MonthName: string; MonthFullName: string }>
) => {
  if (!monthTotals) return [];
  return monthsName.map((item) => {
    if (Object.prototype.hasOwnProperty.call(monthTotals, item.MonthFullName)) {
      return {
        MonthName: item.MonthName,
        Total: monthTotals[item.MonthFullName],
      };
    }
  });
};

export const formatPPADetailData = (data: any) => {
  const temp = { ...data };
  temp.Value = formatNumber(temp.Value, 0);
  temp.MonthRange = [
    formatInputDateToUTCDate(temp.FromDate),
    formatInputDateToUTCDate(temp.ToDate),
  ];
  return temp;
};

export const mergeCountriesData = (data: any) => {
  if (!data) return [];
  return data
    .reduce(
      (total: any[], currentValue: { Countries: any }) =>
        total.concat(currentValue.Countries),
      []
    )
    .sort((first: any, second: any) => first.Name.localeCompare(second.Name));
};

export const addUniqueKeytoItem = (data: any) => {
  return data.map((item: any) => ({
    ...item,
    UniqueKey: generateAlphaNumericUnique(),
  }));
};
export const convertDataFacilitiesMonitoring = (
  value: IFacilitiesByCountry[]
) => {
  return value.map((item: IFacilitiesByCountry) => {
    return {
      id: item.Value,
      name: item.Name,
      url: item.Url,
      lat: item.Latitude,
      long: item.Longitude,
      red: item.Red,
      country: item.CountryName,
      countryId: item.CountryId,
      isOnline: item.IsOnline,
    } as any;
  });
};

export const sumEnergyOneYear = (data: any) => {
  if (!data) return 0;
  return Object.values(data).reduce(
    (previousValue: any, currentValue: any) => previousValue + currentValue,
    0
  ) as number;
};
export const groupDataByName = (data: [], key: string, value: string) => {
  const tempData = data.map((item: any) => [
    item[key],
    {
      text: item[value || key],
      value: item[value || key],
    },
  ]);
  const result = [...new Map(tempData as any).values()];
  return result;
};
