import { IFacilityByCountry } from "@/models/Facility";
import {
  ICountries,
  ICountriesContinents,
  IFacilitiesByCountry,
} from "@/models/Metadata";
import { ErrorResponse } from "@/services/axios/error";
import { MetadataAPI } from "@/services/metadata";
import store from "@/store";
import { distinctCountriesData } from "@/utils/helpers/buildDataFormat";
import { convertDataFacilitiesMonitoring } from "@/utils/helpers/buildDataFormat";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { mergeCountriesData } from "@/utils/helpers/buildDataFormat";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
const name = "MetadataModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class MetadataModule extends VuexModule {
  private errorGetFacilities = {} as ErrorResponse;
  private errorGetAllFacilities = {} as ErrorResponse;
  private errorAllCountriesContinents = {} as ErrorResponse;
  private dataFacilities = [] as IFacilitiesByCountry[];
  private dataFacilitiesOnline = [] as IFacilitiesByCountry[];
  private dataFacilitiesMap = [] as IFacilitiesByCountry[];
  private allCountries = [] as ICountries[];
  private allCountriesContinents = [] as ICountriesContinents[];
  private dataCountriesId = "" as string;
  private isLoadMetadata = false;

  private dataCountries = [] as ICountries[];
  private dataInputManualCountries = [] as ICountries[];
  get dataFacilitiesGetter() {
    return this.dataFacilities;
  }
  get dataFacilitiesOnlineGetter() {
    return this.dataFacilitiesOnline;
  }
  get dataFacilitiesMapGetter() {
    return this.dataFacilitiesMap;
  }

  get dataCountriesGetter() {
    return this.dataCountries;
  }
  get dataCountriesIdGetter() {
    return this.dataCountriesId;
  }
  get dataInputManualCountriesGetter() {
    return this.dataInputManualCountries;
  }
  get dataCountriesContinentsGetter() {
    return this.allCountriesContinents;
  }
  get isLoadMetaDataGetter() {
    return this.isLoadMetadata;
  }
  @Mutation
  setLoadingMetadata(isLoading: boolean) {
    this.isLoadMetadata = isLoading;
  }
  @Mutation
  setCurrentCountries(data: string) {
    this.dataCountriesId = data;
  }
  @Mutation
  getFacilityByCountrySuccess(data: IFacilitiesByCountry[]) {
    this.errorGetFacilities = {} as ErrorResponse;
    this.dataCountries = distinctCountriesData(data) as ICountries[];
    this.dataInputManualCountries = [...this.dataCountries] as ICountries[];
    this.dataInputManualCountries.unshift({
      Name: "All",
      Value: null,
    });
    this.dataFacilities = data;
    this.dataFacilitiesOnline = data.filter(
      (item: IFacilitiesByCountry) => item?.IsOnline
    );
    this.dataFacilitiesMap = convertDataFacilitiesMonitoring(data).filter(
      (item: any) => item?.isOnline
    );

    //this.isLoadMetadata = false;
  }
  @Mutation
  getFacilityByCountryFailure(error: ErrorResponse) {
    this.errorGetFacilities = error;
    //this.isLoadMetadata = false;
  }
  @Action({ rawError: true })
  async getFacilityByCountryAction(payload: IFacilityByCountry = {}) {
    const MetadataService = new MetadataAPI();

    const { data, error } = await MetadataService.getFacilityByCountry(payload);
    if (error) {
      this.getFacilityByCountryFailure(error);
      return;
    }
    this.getFacilityByCountrySuccess(data);
  }

  @Action({ rawError: true })
  async loadingMarketplace() {
    this.setLoadingMetadata(true);
    await Promise.all([
      this.getFacilityByCountryAction(),
      RenewableOptionsModule.getEnergyTypeAction(),
      RenewableOptionsModule.getStandardsAction(),
    ]);
    this.setLoadingMetadata(false);
  }
  get allCountriesGetter() {
    return this.allCountries;
  }
  @Mutation
  getAllCountrySuccess(data: any) {
    this.errorGetAllFacilities = {} as ErrorResponse;
    this.allCountries = mergeCountriesData(data);
  }
  @Mutation
  getAllCountryFailure(error: ErrorResponse) {
    this.errorGetAllFacilities = error;
  }
  @Action({ rawError: true })
  async getAllCountryAction() {
    const MetadataService = new MetadataAPI();
    const { data, error } = await MetadataService.getAllCountry();
    if (error) {
      this.getAllCountryFailure(error);
      return;
    }
    this.getAllCountrySuccess(data);
  }

  @Mutation
  getAllCountryContinentSuccess(data: any) {
    this.errorAllCountriesContinents = {} as ErrorResponse;
    this.allCountriesContinents = data;
  }
  @Mutation
  getAllCountryContinentFailure(error: ErrorResponse) {
    this.errorAllCountriesContinents = error;
  }

  @Action({ rawError: true })
  async getAllCountryContinentAction() {
    const MetadataService = new MetadataAPI();
    const { data, error } = await MetadataService.getAllCountryContinent();
    if (error) {
      this.getAllCountryContinentFailure(error);
      return;
    }
    this.getAllCountryContinentSuccess(data);
  }
}

export default getModule(MetadataModule);
