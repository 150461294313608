import { ISupplyChainManagementDTO } from "@/models/SupplyChainManagement";
import { IPagination } from "@/models/Pagination";
import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { SupplyChainManagementAPI } from "@/services/SupplyChainManagement";

const name = "MetaSupplyChainModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class MetaSupplyChainModule extends VuexModule {
  private errorInvitePartner = {} as ErrorResponse;
  private errorVerificateCompanyName = {} as ErrorResponse;
  private errorGetSupplyChainData = {} as ErrorResponse;
  private errorCheckRole = {} as ErrorResponse;
  private isLoadingSupplyChainManagement = false;
  private isLoadingCheckRole = true;
  private pagination = {} as IPagination;
  private dataGetSupplyChain = [] as ISupplyChainManagementDTO[];
  private dataCheckRole = false as boolean;

  get hasInvitePartnerErrorMsg() {
    return !isEmpty(this.errorInvitePartner);
  }

  get isLoadingSupplyChainManagementGetter() {
    return this.isLoadingSupplyChainManagement;
  }

  get paginationGetter() {
    return this.pagination;
  }

  get listDataGetSupplyChain() {
    return this.dataGetSupplyChain;
  }

  get getCheckRoleGetter() {
    return this.dataCheckRole;
  }
  get isLoadingCheckRoleGetter() {
    return this.isLoadingCheckRole;
  }

  @Mutation
  setLoadingCheckRole(loading: boolean) {
    this.isLoadingCheckRole = loading;
  }
  @Mutation
  getCheckRoleFailure(error: ErrorResponse) {
    this.errorCheckRole = error.message;
    this.isLoadingCheckRole = false;
  }
  @Mutation
  checkRoleSuccess(data: any) {
    this.dataCheckRole = data;
    this.isLoadingCheckRole = false;
  }
  @Action({ rawError: true })
  async checkRole() {
    this.setLoadingCheckRole(true);
    const servicesSupplyChainAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesSupplyChainAPI.checkRole();

    if (error) {
      this.getCheckRoleFailure(error);
    } else {
      this.checkRoleSuccess(data);
    }
  }
}

export default getModule(MetaSupplyChainModule);
